<template>
    <div>

		<div class="sign-up-header" style="background-image: url('https://demos.creative-tim.com/muse-vue-ant-design-dashboard/images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-3">INICIAR SESIÓN</h1>
				<p class="fs-6">Inicia Sesión ingresando tu Usuario y Contraseña</p>
			</div>
		</div>
		
		<a-card :bordered="false" class="card-signup header-solid h-100" :bodyStyle="{paddingTop: 0}">
        
            <template #title>
				<h5 class="font-semibold text-center">Logo</h5>
			</template>

			<a-form id="components-form-demo-normal-login" :model="formState" @finish="iniciarSesion" @finishFailed="iniciarSesionFallido" layout="vertical">

                <a-form-item class="mb-3" label="Número RUC:" name="ruc" :rules="[{ required: true , message: 'El Número de RUC es Obligatorio.' }]">
                    <a-input v-model:value="formState.ruc" name="ruc" placeholder="Ingresa el Número de RUC.">
                        <template #prefix > <font-awesome-icon :icon="['fas', 'hashtag']" class="site-form-item-icon" /> </template>
                    </a-input>
                </a-form-item>

                <a-form-item class="mb-3" label="Usuario:" name="usuario" :rules="[{ required: true , message: 'El USUARIO es Obligatorio.' }]">
                    <a-input v-model:value="formState.usuario" name="usuario" placeholder="Ingresa su Nombre de Usuario.">
                        <template #prefix > <font-awesome-icon :icon="['far', 'user']" class="site-form-item-icon" /> </template>
                    </a-input>
                </a-form-item>

                <a-form-item class="mb-3" label="Contraseña:" name="clave" :rules="[{ required: true, message: 'La CONTRASEÑA es Obligatorio.' }]">
                    <a-input-password v-model:value="formState.clave" name="clave" placeholder="Ingresa la Contraseña.">
                        <template #prefix > <LockOutlined class="site-form-item-icon" /> </template>
                    </a-input-password>
                </a-form-item>
                
                <a-form-item class="mb-10">
                    <a-switch v-model:checked="formState.recuerdame" /> Recuerdame
                </a-form-item>

                <a-form-item class="text-center">
                    <a-button :loading="loading" type="primary" html-type="submit" class="w-100"> INICIAR SESIÓN </a-button>
                </a-form-item>                
			</a-form>
			<p class="font-semibold text-muted text-center">¿No tienes una cuenta? <router-link to="/register" class="font-bold text-dark">Registrate Ahora</router-link></p>
		</a-card>

	</div>
</template>

<script>
    import { defineComponent, reactive, ref, computed } from 'vue';
    import { LockOutlined } from '@ant-design/icons-vue';
    // import { useRouter, useRoute } from 'vue-router';
    import { useStore } from "vuex";
    
    export default defineComponent({
        layout: {
            name: 'AuthLayout',
            props: {
                layoutClass: 'layout-sign-up'
            }
        },
        components: { LockOutlined},
        setup() {
            const store = useStore();

            // const router = useRouter();
            // const route = useRoute();

            const loading = ref(false);
            
            const formState = reactive({
                ruc: '',
                usuario: '',
                clave: '',
                recuerdame: false,
            });
                            
            const iniciarSesion = () => {
                loading.value = true;
                store.dispatch("auth/login", formState).then(
                    response => {
                        window.location = '/principal'
                        // router.push({ name: 'Principal', query: { ...route.query } });
                    },
                    error => {
                        let message = error.response ? error.response.data.error : error.message;
                        AntN.error('Inicio de Sesión', message);
                    }
                ).then(() => loading.value = false)
            }

            const iniciarSesionFallido = error => {
                document.getElementsByName(error.errorFields[0].name[0])[0].select();
            };

            return { formState, loading, iniciarSesion, iniciarSesionFallido };
        }
        
    });
</script>